//==============================================================================
// Conditional Container
//
// Container module that can be disabled to hide child content
//==============================================================================
import * as React from 'react';

import { IConditionalContainerData } from './conditional-container.data';
import { IConditionalContainerProps } from './conditional-container.props.autogenerated';

//==============================================================================
// CLASS DEFINITION
//==============================================================================
/**
 * ConditionalContainer component
 * @extends {React.PureComponent<IConditionalContainerProps<IConditionalContainerData>>}
 */
//==============================================================================
class ConditionalContainer extends React.PureComponent<IConditionalContainerProps<IConditionalContainerData>> {

    //==========================================================================
    // PUBLIC METHODS
    //==========================================================================

    //------------------------------------------------------
    // Render function
    //------------------------------------------------------
    public render(): JSX.Element | null {
        return this._displayPromo();
    }

    //==========================================================================
    // PRIVATE METHODS
    //==========================================================================

    //------------------------------------------------------
    //------------------------------------------------------
    private _displayPromo(): JSX.Element | null {
        const { config, slots } = this.props;
        const hasSlots = slots && slots.contentBlock && slots.contentBlock.length;

        if (hasSlots) {
            return (
                <React.Fragment>
                    {config.enabled &&
                        this._renderSlotItems(slots.contentBlock)
                    }
                </React.Fragment>
            );
        }

        // No slots, so there's nothing to do
        return null;
    }

    //------------------------------------------------------
    //------------------------------------------------------
    private _renderSlotItems(items: React.ReactNode[]): JSX.Element {
        return (
            <React.Fragment>
                {items.map((slot: React.ReactNode, index: number) => (
                    <React.Fragment key={index}>
                        {slot}
                    </React.Fragment>
                ))}
            </React.Fragment>
        );
    }
}

export default ConditionalContainer;
